html, body, #root {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* ------ Logo Spinner ------ */

.spinner .pin path {
  stroke: #ccba78;
  stroke-width: 2px;
  transform-box: fill-box;
  transform-origin: 50% 50%;
}

#red-pin {
  fill: #c1272e;
}
#gold-pin,
circle {
  fill: #e0ca84;
}
#purple-pin {
  fill: #835da7;
}
#blue-pin {
  fill: #1172ba;
}
#green-pin {
  fill: #009469;
}
#orange-pin {
  fill: #f78f1e;
}

/* ----- Spinner Rotate ----- */

.spinner-linear {
  animation: logo-spinner 3s 0s linear infinite backwards;
}

/* ----- Spinner Rotate Ease ----- */

.spinner-ease {
  animation: logo-spinner 3s 0s ease-in-out infinite backwards;
  transform-box: fill-box;
}

@keyframes logo-spinner {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* ----- Spinner Pulse ----- */

@keyframes pulse {
  50% {
    transform: scale(1.03);
  }
}

.spinner-pulse .pin {
  animation: pulse 2s ease-in-out 1.3s infinite normal backwards;
}

.spinner-pulse .pin:nth-of-type(2) {
  animation-delay: 1.7s;
}

.spinner-pulse .pin:nth-of-type(3) {
  animation-delay: 2.1s;
}

.spinner-pulse .pin:nth-of-type(4) {
  animation-delay: 2.5s;
}

.spinner-pulse .pin:nth-of-type(5) {
  animation-delay: 2.9s;
}

.spinner-pulse .pin:nth-of-type(6) {
  animation-delay: 3.3s;
}
